import './env';
import './sentry';
import $router from '@whitetail/buckshot/scripts/buckshot-routes';
import $mediator from '@whitetail/buckshot/scripts/buckshot-mediator';
import './bootstrap';

//  Cache bust 2023-06-20 abcdefg

const Header = () => import('../components/header');
const Footer = () => import('../components/footer');
const Forms = () => import('../components/forms');
const CardGrid = () => import('../components/card-grid');
const ShareModal = () => import('../components/share-modal');
const CareerSearch = () => import('../components/career-search');
const PropertySubnav = () => import('../components/property-detail/subnav');
const Modal = () => import('../components/modal');
const SvgMap = () => import('../components/svg-map');
const LandSearch = () => import('../components/hero/search');
const PropertySlider = () => import('../components/property-slider');
const PropertyCard = () => import('../components/card/property');
const Properties = () => import('../components/properties');
const Media = () => import('../components/media');
const AgentSearch = () => import('../components/find-an-agent');
const LandTypeSlides = () => import('../components/land-type-slides');
const ImageSlider = () => import('../components/image-slider');
const ImageGrid = () => import('../components/property-detail/image-grid');
const Sponsors = () => import('../components/sponsors');
const Seasons = () => import('../components/tv-grid');
const TvCard = () => import('../components/card/tv');
const ReadFull = () => import('../components/property-detail');
const ListingFilters = () => import('../components/agent-listings');
const AgentTestimonial = () => import('../components/agent-detail/testimonial');
const AgentDetail = () => import('../components/agent-detail');
const MywpCta = () => import('../components/cta/mywp');
const Mywp = () => import('../components/mywp');
const LoginModal = () => import('../components/login-modal');
const WhyJoin = () => import('../components/why-join');
const LandCalculator = () => import('../components/land-calculator');
const SaveMedia = () => import('../components/mywp/save/media');
const RecommendedPropertySlider = () => import('../components/recommended-property-slider');
const KnowledgeCenterAnalytics = () => import('../components/analytics/knowledge');
const PropertyAnalytics = () => import('../components/analytics/property');
const AgentAnalytics = () => import('../components/analytics/agent');
const ContactAnalytics = () => import('../components/analytics/contact');
// contact compenent and add route below
const Contact = () => import('../components/contact');
const ListYourProperty = () => import('../components/list-your-property');

window.$mediator = $mediator;

const common = [
    Header,
    Footer,
    Forms,
    Modal,
    PropertyCard,
    CardGrid,
    LoginModal,
];

const listings = [
    ...common,
    PropertySubnav,
    ImageGrid,
    Media,
    ShareModal,
    SvgMap,
    ReadFull,
];

$router.map([
    {
        path: '/',
        handler: [
            ...common,
            PropertySlider,
            SvgMap,
            LandSearch,
            MywpCta,
            RecommendedPropertySlider,
        ],
    },
    {
        path: 'hunting-land',
        handler: [
            ...common,
            Properties,
        ],
    },
    {
        path: 'hunting-land/:state/:county/*',
        handler: [
            ...listings,
            PropertySlider,
            PropertyAnalytics,
        ],
    },
    {
        path: 'hunting-land/:state',
        handler: [
            ...common,
            PropertySlider,
            LandSearch,
        ],
    },
    {
        path: 'preview/listings-bed/*',
        handler: [
            ...listings,
            PropertySlider,
        ],
    },
    {
        path: 'agents/*',
        handler: [
            ...common,
            AgentDetail,
            Media,
            AgentTestimonial,
            ListingFilters,
            AgentAnalytics,
        ],
    },
    {
        path: 'why-us',
        handler: [
            ...common,
            LandTypeSlides,
        ],
    },
    {
        path: 'list-your-property',
        handler: [
            ...common,
            ListYourProperty,
        ],
    },
    {
        path: 'contact',
        handler: [
            ...common,
            ContactAnalytics,
            Contact,
        ],
    },
    {
        path: 'find-an-agent',
        handler: [
            ...common,
            AgentSearch,
        ],
    },
    {
        path: 'find-an-agent/*',
        handler: [
            ...common,
            AgentSearch,
        ],
    },
    {
        path: 'find-a-specialist',
        handler: [
            ...common,
            AgentSearch,
        ],
    },
    {
        path: 'find-a-specialist/*',
        handler: [
            ...common,
            AgentSearch,
        ],
    },
    {
        path: 'careers',
        handler: [
            ...common,
            ImageSlider,
            SvgMap,
            CareerSearch,
        ],
    },
    {
        path: 'careers/search',
        handler: [
            ...common,
            SvgMap,
            CareerSearch,
        ],
    },
    {
        path: 'careers/path/(.*)',
        handler: [
            ...common,
            SvgMap,
            CareerSearch,
        ],
    },
    {
        path: 'landbeat',
        handler: [
            ...common,
            Sponsors,
        ],
    },
    {
        path: 'landbeat/playlists/:list?',
        handler: [
            ...common,
            Sponsors,
            Seasons,
            TvCard,
        ],
    },
    {
        path: 'landbeat/:video',
        handler: [
            ...common,
            Sponsors,
            Seasons,
            ShareModal,
            SaveMedia,
        ],
    },
    {
        path: 'wptv',
        handler: [
            ...common,
            Seasons,
            Sponsors,
            ShareModal,
        ],
    },
    {
        path: 'wptv/:list?',
        handler: [
            ...common,
            Seasons,
            Sponsors,
            ShareModal,
        ],
    },
    {
        path: 'videos/(.*)',
        handler: [
            ...common,
            Sponsors,
            Seasons,
            ShareModal,
            SaveMedia,
        ],
    },
    {
        path: 'knowledge-center/(.*)',
        handler: [
            ...common,
            SvgMap,
            LandSearch,
            SaveMedia,
            KnowledgeCenterAnalytics,
        ],
    },
    {
        path: 'mywp',
        handler: [
            ...common,
            Mywp,
        ],
    },
    {
        path: 'mywp/why-join',
        handler: [
            ...common,
            WhyJoin,
        ],
    },
    {
        path: 'land-calculator',
        handler: [
            ...common,
            LandCalculator,
        ],
    },
    {
        path: 'land-calculator/(.*)',
        handler: [
            ...common,
            LandCalculator,
        ],
    },
]).notFound(
    {
        handler: [
            ...common,
            LandSearch,
            SvgMap,
        ],
    },
).run();
