import { $setVar } from '@whitetail/buckshot/scripts/buckshot-store';
import { $setRef } from '@whitetail/buckshot/scripts/core/dom';
import { $chain } from '@whitetail/buckshot/scripts/core/chain';
import { $isObject } from '@whitetail/buckshot/scripts/core/types';
import 'es6-promise/auto';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../styles/app.scss';

// Require all component scss files
require.context('../components', true, /\.scss$/);

$setRef();
$setVar();

$chain({
    /**
     * Set an aria attribute and value
     *
     * @param {string|object} attr - valid aria attribute
     * @param {string|boolean} [value] - valid aria attribute value
     * @return {this}
     */
    aria(attr, value = null) {
        if ($isObject(attr)) {
            Object.keys(attr).forEach((key) => {
                this.aria(key, attr[key]);
            });
        } else {
            this.attr(`aria-${attr}`, value);
        }

        return this;
    },

    /**
     * Set the aria attribute 'expanded'
     *
     * @param {boolean} [expand]
     * @returns {this}
     */
    ariaExpand(expand = true) {
        this.aria('expanded', expand);

        return this;
    },

    /**
     * Set the aria attribute 'hidden'
     *
     * @param {boolean} [hidden]
     * @return {this}
     */
    ariaHidden(hidden = true) {
        this.aria('hidden', hidden);

        return this;
    },

    /**
     * Set the aria attribute 'expanded'
     *
     * @param {boolean} [expand]
     * @returns {this}
     */
    ariaSelected(selected = true) {
        this.aria('selected', selected);

        return this;
    },
});
