import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
    dsn: 'https://529fed831b4e406b98eba16f7c63068c@o1344187.ingest.sentry.io/4504756185595904',
    integrations: [new BrowserTracing()],
    ignoreErrors: ['AbortError', 'ChunkLoadError'],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
});