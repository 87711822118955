import { $setVar } from '@whitetail/buckshot/scripts/buckshot-store';
import { $env } from '@whitetail/buckshot/scripts/core/core';

$setVar();

$env({
    production: 'www.whitetailproperties.com',
    staging: 'wpsitestage.dev',
    sandbox: 'wpsite.sandbox.us',
    localdev: 'wpsite.dev',
    local: 'wpsite.test',
});
